import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface IWorkspace {
  id: string;
  type: string;
  name: string;
  rootFolderId: string;
  owner: boolean;
}

const initialState: IWorkspace = {
  id: "",
  type: "",
  name: "",
  rootFolderId: "",
  owner: true,
};

export type WorkspaceSliceStateType = Pick<RootState, "workspace">;

export const workspaceSlice = createSlice({
  name: "[workspaceSlice]",
  initialState,
  reducers: {
    setWorkspace(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.rootFolderId = action.payload.rootFolderId;
      state.owner = action.payload.owner;
    },
  },
});

export const { setWorkspace } = workspaceSlice.actions;

export default workspaceSlice.reducer;
