import { ENV_CONSTANTS } from "@/common/constants/env.const";
import axios from "axios";

export default class WorkspaceAPIs {
  private static baseUrl = "/workspaces";

  public static async RequestWorkspaces() {
    return await axios.get(`${ENV_CONSTANTS.API_BASE_URL_V2}${this.baseUrl}`);
  }
}
